
    .main-content-wrapper {
        padding: 25px 40px;
    }

    .admin-view-toolbar {
        padding-top: calc(var(--global-margin) * 2);
        padding-left: calc(var(--global-margin) * 2);
        padding-right: calc(var(--global-margin) * 2);
    }

  .title-back-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5em;
      width: 1.5em;
  }


